import applyGeometryComponents from "../../utils/applyGeometryComponents";

const equals = () => {
	// Temporary removed until we test that tracking works perfectly
	// applyGeometryComponents("wf-form-ipay_leadgen");
	// applyGeometryComponents("wf-form-Partnerships---Request-a-callback-form");
	// applyGeometryComponents("wf-form-Partnerships---Request-a-free-demo-form");
	// applyGeometryComponents("wf-form-Home---Request-a-callback-form");
	// applyGeometryComponents(
	// 	"wf-form-Expense-management---Request-a-callback-form"
	// );
	// applyGeometryComponents("wf-form-Expense-management---Request-a-free-demo");
	// applyGeometryComponents(
	// 	"wf-form-Payment-platform---Request-a-callback-form"
	// );
	// applyGeometryComponents(
	// 	"wf-form-Broker-platform---Request-a-callback-form"
	// );
};

export default equals();
